

export class EditProfile {
    public static readonly type = '[Profile] Edit profile';
}

export class EditProfileAddress {
    public static readonly type = '[Profile] Edit profile address';
}

export class GetProfile {
    public static readonly type = '[Profile] Get profile';
    constructor(public forceRefetch?: boolean) {
    }
}

export class GoToEditProfile {
    public static readonly type = '[Profile] Go to edit profile';
}

export class GoToEditAddressProfile {
    public static readonly type = '[Profile] Go to edit address profile';
}

export class CancelEditProfile {
    public static readonly type = '[Profile] Cancel edit profile';
}

export class CancelEditAddressProfile {
    public static readonly type = '[Profile] Cancel edit address profile';
}