import {HttpEvent, HttpEventType} from '@angular/common/http';
import {tap} from 'rxjs';

export function uploadProgress<T>(callback: (progress: number) => void) {
    return tap((event: HttpEvent<T>) => {
        if (event.type === HttpEventType.UploadProgress) {
            callback(Math.round((100 * event.loaded) / event.total));
        }
    });
}
