import {Injectable} from '@angular/core';
import {Action, Selector, State, StateContext, Store} from '@ngxs/store';
import {IProfile} from 'src/app/interfaces/profile';
import {
    CancelEditAddressProfile,
    CancelEditProfile,
    EditProfile,
    EditProfileAddress,
    GetProfile,
    GoToEditAddressProfile,
    GoToEditProfile,
} from './profile.actions';
import {ProfileService} from '../../modules/profile/profile.service';
import {Navigate} from '@ngxs/router-plugin';
import {UpdateFormValue} from '@ngxs/form-plugin';
import {firstValueFrom} from 'rxjs';
import {AppInsightsService} from 'src/app/core/app-insights/app-insights.service';
import {errorHandler, IErrorHandlerArgs} from 'src/app/shared/helpers/error-handler';
import {ProfileMediaApiService} from 'src/app/shared/services/profile-media-api.service';

export interface IProfileState {
    data: Partial<IProfile>;
    profileForm: {
        model: Partial<IProfile>;
    };
    addressForm: {
        model: Partial<IProfile>;
    };
    loading: boolean;
    hasValue: boolean;
    error: any;
}

@State<IProfileState>({
    name: 'profile',
    defaults: {
        data: null,
        profileForm: {
            model: null,
        },
        addressForm: {
            model: null,
        },
        loading: false,
        hasValue: false,
        error: null,
    },
})
@Injectable()
export class ProfileState {
    private readonly _errorHandleInitArgs: IErrorHandlerArgs = {
        error: null,
        appInsightsSrv: this.insight,
        scope: 'ProfileState',
    };
    constructor(
        private store: Store,
        private profile: ProfileService,
        private insight: AppInsightsService,
        private profileMediaApi: ProfileMediaApiService,
    ) { }

    @Selector()
    public static profile(state: IProfileState): Partial<IProfile> {
        return state.data;
    }

    @Selector()
    public static model(state: IProfileState): Partial<IProfile> {
        return state.profileForm.model;
    }

    @Selector()
    public static loading(state: IProfileState): boolean {
        return state.loading;
    }

    @Selector()
    public static hasValue(state: IProfileState): boolean {
        return state.hasValue;
    }

    @Selector()
    public static error(state: IProfileState): any {
        return state.error;
    }

    @Action(GetProfile)
    public async getProfile(
        ctx: StateContext<IProfileState>,
        {forceRefetch}: GetProfile
    ): Promise<any> {
        const state = ctx.getState();
        ctx.patchState({loading: true});
        let profile: Partial<IProfile>;
        if (state.hasValue && !forceRefetch) {
            profile = state.data;
        } else {
            try {
                profile = await firstValueFrom(this.profile.getProfile());
            } catch (error: any) {
                errorHandler({
                    ...this._errorHandleInitArgs,
                    error,
                    fallbackMessage: 'An unknown error occurred during getting profile information'
                });
                ctx.patchState({
                    data: null,
                    hasValue: false,
                    loading: false,
                    error: error.error ?? error.message ?? error.statusText,
                });
            }
        }

        this.store.dispatch(
            new UpdateFormValue({
                path: 'profile.profileForm',
                value: profile,
            })
        );
        this.store.dispatch(
            new UpdateFormValue({
                path: 'profile.addressForm',
                value: profile,
            })
        );
        ctx.patchState({data: profile, loading: false, hasValue: !!profile});
    }

    @Action(EditProfile)
    public async editProfile(ctx: StateContext<IProfileState>): Promise<any> {
        ctx.patchState({loading: true});
        const currentProfileImage = ctx.getState().data.profileImage;
        const model = ctx.getState().profileForm.model;
        let birthDate: any = '';
        if (model.birthDate) {
            const copy = new Date(model.birthDate);
            birthDate = `${copy.getFullYear()}-${copy.getMonth() + 1}-${copy.getDate()}`;
        }

        let profileImage = ctx.getState().profileForm.model.profileImage;

        try {
            // if profileImage is an object, it means that the user has uploaded a new image
            if (Boolean(profileImage) && typeof profileImage === 'object') {
                const file = (profileImage as any).file as File;
                const profileMedia = await firstValueFrom(this.profileMediaApi.uploadProfilePicture({file}, () => { }));
                profileImage = profileMedia.identifier;
            }

            // if the user has uploaded a new image, we need to remove the old one
            // if the user has removed the image, we need to remove it
            if (currentProfileImage && profileImage && currentProfileImage !== profileImage) {
                await firstValueFrom(this.profileMediaApi.delete(currentProfileImage));
            }

            // if the user has removed the image, we need to remove it
            if (currentProfileImage && !profileImage) {
                await firstValueFrom(this.profile.deleteProfilePic());
            }

            const payload: Partial<IProfile> = {
                ...model,
                birthDate,
                profileImage,
            };

            const profile = await firstValueFrom(this.profile.updateProfile(payload));
            this.store.dispatch(
                new UpdateFormValue({
                    path: 'profile.profileForm',
                    value: profile,
                })
            );
            this.store.dispatch(
                new UpdateFormValue({
                    path: 'profile.addressForm',
                    value: profile,
                })
            );
            ctx.patchState({data: profile, loading: false, hasValue: !!profile});
            this.store.dispatch(new Navigate(['/profile']));
        } catch (error) {
            errorHandler({
                ...this._errorHandleInitArgs,
                error,
                fallbackMessage: 'An unknown error occurred during updating profile information',
            });
            ctx.patchState({
                loading: false,
                error: error.error ?? error.message ?? error.statusText,
            });
        }
    }

    @Action(EditProfileAddress)
    public async editProfileAddress(ctx: StateContext<IProfileState>): Promise<any> {
        const currentState = ctx.getState();
        try {
            ctx.patchState({loading: true, error: null});
            const model: Partial<IProfile> = currentState.addressForm.model;
            const profile = await firstValueFrom(this.profile.updateProfile(model));
            ctx.patchState({data: profile, loading: false, hasValue: !!profile});
            this.store.dispatch(
                new UpdateFormValue({
                    path: 'profile.profileForm',
                    value: profile,
                })
            );
            this.store.dispatch(
                new UpdateFormValue({
                    path: 'profile.addressForm',
                    value: profile,
                })
            );
            this.store.dispatch(new Navigate(['/profile']));
        } catch (error) {
            ctx.patchState({
                ...currentState,
                hasValue: false,
                loading: false,
                error: error.error ?? error.message ?? error.statusText,
            });
            errorHandler({
                ...this._errorHandleInitArgs,
                error,
                fallbackMessage: 'An unknown error occurred during updating profile address information',
            });
        }
    }

    @Action(GoToEditProfile)
    public goToEditProfile(): void {
        this.store.dispatch(new Navigate(['/profile/edit']));
    }

    @Action(GoToEditAddressProfile)
    public goToEditAddressProfile(): void {
        this.store.dispatch(new Navigate(['/profile/address']));
    }

    @Action([CancelEditProfile, CancelEditAddressProfile])
    public cancelEditProfile(ctx: StateContext<IProfileState>): void {
        const profile = ctx.getState().data;
        this.store.dispatch(
            new UpdateFormValue({
                path: 'profile.profileForm',
                value: profile,
            })
        );
        this.store.dispatch(
            new UpdateFormValue({
                path: 'profile.addressForm',
                value: profile,
            })
        );
        this.store.dispatch(new Navigate(['/profile']));
    }
}
