import {HttpClient, HttpEventType, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {catchError, EMPTY, filter, from, map, mergeMap, Observable, toArray} from 'rxjs';
import {ConfigurationService} from 'src/app/core/config/configuration.service';
import {IProfileMedia} from 'src/app/interfaces/profile-media';
import {
    IProfileMediaFilePayloadForRequest,
    IProfileMediaUploadRequest,
} from 'src/app/interfaces/profile-media-upload.request';
import {uploadProgress} from 'src/app/shared/helpers/upload-progress';

@Injectable({
    providedIn: 'root'
})
export class ProfileMediaApiService {

    constructor(
        private http: HttpClient,
        private cfg: ConfigurationService,
    ) { }

    public getNoImage(): Observable<Blob> {
        return this.http.get<Blob>('assets/images/no-image.png', {
            responseType: 'blob' as 'json',
        });
    }

    public getAllProfileMedia(): Observable<IProfileMedia[]> {
        return this.http.get<IProfileMedia[]>(`${this.cfg.get('profileApiUrl')}/media`);
    }

    public getProfileMedia(identifier: string): Observable<IProfileMedia> {
        return this.http.get<IProfileMedia>(`${this.cfg.get('profileApiUrl')}/media/${identifier}`);
    }

    public getMedia(identifier: string): Observable<Blob> {
        return this.http.get<Blob>(`${this.cfg.get('profileApiUrl')}/media/${identifier}/download`, {
            responseType: 'blob' as 'json',
        });
    }

    public delete(identifier: string): Observable<void>;
    public delete(identifiers: string[]): Observable<void>;
    public delete(identifiers: string | string[]): Observable<void> {
        let ids: string[] = [];
        if (typeof identifiers === 'string') {
            ids.push(identifiers);
        }
        if (Array.isArray(identifiers)) {
            ids = identifiers;
        }

        return from(ids).pipe(
            mergeMap((id) => this.http.delete<void>(`${this.cfg.get('profileApiUrl')}/media/${id}`).pipe(
                catchError((error) => {
                    console.error(error);
                    return EMPTY;
                }),
            )),
            toArray(),
            map(() => undefined),
        );
    }

    public deleteWithoutHandlingError(id: string): Observable<void> {
        return this.http.delete<void>(`${this.cfg.get('profileApiUrl')}/media/${id}`);
    }

    public upload<T>(
        req: IProfileMediaUploadRequest,
        uploadCallback: (progress: number) => void,
    ): Observable<T> {
        const {file, name, additionalType} = req;
        const formData = new FormData();
        formData.append('file', file);
        formData.append('name', name ?? file.name);
        formData.append('additionalType', additionalType);

        return this.http.post<T>(
            `${this.cfg.get('profileApiUrl')}/media`,
            formData,
            {
                reportProgress: true,
                observe: 'events',
            }
        ).pipe(
            uploadProgress(uploadCallback),
            filter((event) => event.type === HttpEventType.Response),
            map((response: HttpResponse<T>) => response.body),
        );
    }

    public uploadProfilePicture(
        req: IProfileMediaFilePayloadForRequest,
        uploadCallback: (progress: number) => void
    ): Observable<IProfileMedia> {
        return this.upload<IProfileMedia>({...req, additionalType: 'ProfileImage'}, uploadCallback);
    }

    public uploadTravelerPicture(
        req: IProfileMediaFilePayloadForRequest,
        uploadCallback: (progress: number) => void,
    ): Observable<IProfileMedia> {
        return this.upload<IProfileMedia>({...req, additionalType: 'TravelerProfileImage'}, uploadCallback);
    }

    public uploadSupportingDocument(
        req: IProfileMediaFilePayloadForRequest,
        uploadCallback: (progress: number) => void,
    ): Observable<IProfileMedia> {
        return this.upload<IProfileMedia>({...req, additionalType: 'SupportingDocument'}, uploadCallback);
    }

}
